<neo-matris-loader></neo-matris-loader>
<neo-matris-toast></neo-matris-toast>
<div [ngClass]="getRoute()">
  <div>
    <neo-matris-menubar #menubar [showOrders]="showOrders" [username]="username" [companyName]="companyName"></neo-matris-menubar>
    <app-report-issue></app-report-issue>
  </div>
  <div class="content-wrap">
    <ng-container *ngIf="">
    </ng-container>
      <router-outlet></router-outlet>
  </div>
  <div class="footer">
    <neo-matris-footer></neo-matris-footer>
  </div>
</div>