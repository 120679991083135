
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ImpulsesService } from 'src/app/services/impulses.service';
import { MENU_ITEMS } from '../models/menu-items.list';
import { checkTimeRange } from '../utils/time-range';
import { environment } from 'src/environments/environment';
import { TabStateService } from 'src/app/services/tab-state.service';
import { ReportIssueDialogService } from 'src/app/services/report-issue-dialog.service';

@Component({
  selector: 'neo-matris-menubar',
  templateUrl: './matris-menubar.component.html',
  styleUrls: ['./matris-menubar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MatrisMenubar {
  @Input() username: string = ''
  @Input() companyName: string = ''
  showOrders: boolean = false;
  showProcurementHub: boolean = false;
  showTQDCS: boolean = false;
  items: any = [];
  messages: any[];
  bannerMessage: string = '';
  isBannerMessageVisible: boolean = false;
  version: string;
  showMenubarSkeleton: boolean = true;
  header: string = '';
  isFeedbackDialogVisible: boolean = false;

  

  closeFeedbackDialog() {
    this.isFeedbackDialogVisible = false;
  }

  constructor(private router: Router, private impulsesService: ImpulsesService, 
    private tabStateService: TabStateService,private reportDialogService: ReportIssueDialogService) {
    this.version = environment.version ? environment.version : ''
    this.menubarStyle();
  }

  openFeedbackDialog() {
    //this.dialog.displayDialog = true;
    //this.isFeedbackDialogVisible = true;
    this.reportDialogService.setShowReportIssueDialogStatus(true);
  }
  onFeedbackClick(op: any) {
    op.hide(); 
    setTimeout(() => {
      this.openFeedbackDialog(); 
    }, 0); 
  }
  ngOnInit() {

  }

  menubarStyle() {
    
    document.documentElement.style.setProperty(
      '--menubar-color',
      environment.menubar || '#041e42'
    );

    document.documentElement.style.setProperty(
      '--menubar-hover-color',
      environment.hover || '#1f0838'
    );

  }

  setMenubarItems() {

    document.documentElement.style.setProperty(
      '--menubar-color',
      environment.menubar || '#041e42'
    );

    document.documentElement.style.setProperty(
      '--menubar-hover-color',
      environment.hover || '#1f0838'
    );


    if (environment.production == true) {
      this.header = '';
    } else {
      this.header = 'DEV';
    }

    this.impulsesService.getFeatureFlag().subscribe({
      next: (data) => {
        let response = data as any;
        if (response) {
          // Set duration to show banner
          const start = response.generalConfig.banner.startTime;
          const end = response.generalConfig.banner.endTime;
          let showBanner = response.generalConfig.banner.isVisible && checkTimeRange(start, end);
          this.isBannerMessageVisible = showBanner ? showBanner : false;
          this.bannerMessage = response.generalConfig.banner.message ? response.generalConfig.banner.message : 'Please close this message. There seems to be some issue fetching the message content.';
          this.messages = [
            { severity: 'info', detail: this.bannerMessage }
          ]
          this.items = [];
          this.items = MENU_ITEMS;
          this.showOrders = response.featureFlagConfig.showOrders.enabled ? response.featureFlagConfig.showOrders.enabled : false;
          this.showProcurementHub = response.featureFlagConfig.showProcurementStartegyHub.enabled ? response.featureFlagConfig.showProcurementStartegyHub.enabled : false;
          this.showTQDCS = response.featureFlagConfig.showTQDCS.enabled ? response.featureFlagConfig.showTQDCS.enabled : false;
          // Hide Menubar Skeleton 
          this.showMenubarSkeleton = false;
          // Show order tab based on feature flag
          if (this.showOrders) {
            this.items = [];
            this.items = MENU_ITEMS;
            this.addMenuItem({
              label: 'Orders', routerLink: 'orders', command: () => {
                MENU_ITEMS[1].routerLink = "";
                if (MENU_ITEMS.find(e => e.label === 'Procurement Strategy Hub')) {
                  MENU_ITEMS[4].routerLink = "";
                }
              }
            });
            this.checkProcurementTab();
          } else {
            this.checkProcurementTab();
          }
        }
      },
      error: (err) => {
        this.items = [];
        this.items = MENU_ITEMS;
        // Hide Menubar Skeleton 
        this.showMenubarSkeleton = false;
      }
    })
  }

  checkProcurementTab() {
    if (this.showProcurementHub) {
      if (this.showTQDCS) {
        this.addMenuItem(
          {
            label: 'Procurement Strategy Hub',
            routerLink: "",
            command: () => {
              MENU_ITEMS[1].routerLink = "";
            },
            items: [
              {
                label: 'MATG Team', routerLink: "procurement-strategy-hub/matg-team", command: () => {
                  MENU_ITEMS[4].routerLink = "procurement-strategy-hub/matg-team";
                }
              },
              {
                label: 'TQDCS', routerLink: "procurement-strategy-hub/tqdcs", command: () => {
                  MENU_ITEMS[4].routerLink = "procurement-strategy-hub/tqdcs";
                }
              },
              {
                label: 'Sourcing Planning', routerLink: "procurement-strategy-hub/sourcing-planning", command: () => {
                  MENU_ITEMS[4].routerLink = "procurement-strategy-hub/sourcing-planning";
                }
              },
              {
                label: 'X Ring', routerLink: "procurement-strategy-hub/x-ring-team/:team", command: () => {
                  MENU_ITEMS[4].routerLink = "procurement-strategy-hub/x-ring-team/:team";
                }
              }

            ]
          }
        );
        if (this.tabStateService.isTQDCSActive) {
          MENU_ITEMS[4].routerLink = "procurement-strategy-hub/tqdcs";
        } else if (this.tabStateService.isMATGActive) {
          MENU_ITEMS[4].routerLink = "procurement-strategy-hub/matg-team";
        }
      } else {
        this.addMenuItem(
          {
            label: 'Procurement Strategy Hub',
            routerLink: "",
            command: () => {
              MENU_ITEMS[1].routerLink = "";
            },
            items: [
              {
                label: 'MATG Team', routerLink: "procurement-strategy-hub/matg-team", command: () => {
                  MENU_ITEMS[4].routerLink = "procurement-strategy-hub/matg-team";
                }
              }
            ]
          })
        if (this.tabStateService.isMATGActive) {
          MENU_ITEMS[4].routerLink = "procurement-strategy-hub/matg-team";
        }
      }
    }
  }

  addMenuItem(newItem: any) {
    this.items.push(newItem);
  }



  goToHome() {
    this.router.navigate(['/']);
  }

  redirectToHomePage() {
    this.router.navigate(['/']);
  }

}

