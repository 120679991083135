import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { DetailsModule } from './details/details.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';

// import { DetailsComponent } from './details/details/details.component';
import { HttpInterceptor } from '@angular/common/http';
import { MatrisneocommonModule } from './matrisneocommon/matrisneocommon.module';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { DatePipe } from '@angular/common'
// import { RequisitionDetailsComponent } from './details/requisition-details/requisition-details.component';
// import { DetailsPlanningInformationComponent } from './details/details-planning-information/details-planning-information.component';
// import { DetailsClassificationSegmentComponent } from './details/details-classification-segment/details-classification-segment.component';
// import { DetailsClassificationMaterialGroupsComponent } from './details/details-classification-material-groups/details-classification-material-groups.component';
// import { DetailsSourcingStrategyComponent } from './details/details-sourcing-strategy/details-sourcing-strategy.component';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
// import { ToastComponent } from './toast/toast.component';
// import { ToastService } from './services/toast.service';
import { CustomHttpInterceptor } from './interceptors/custom-http-interceptor';
import { TokenService } from './services/token.service';
import { CookieService } from 'ngx-cookie-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatrisLoaderHandlerService } from './matrisneocommon/matris-loader-handler.service';
import { RouteReuseStrategy } from '@angular/router';
import { RouteReuseService } from './services/route-reuse.service';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ReportIssueComponent } from './report-issue/report-issue.component';
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: environment.redirectUrl
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', environment.scopes);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.scopes
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    // DetailsComponent,
    SideMenuComponent,
    ReportIssueComponent,
    
    // RequisitionDetailsComponent,
    // DetailsPlanningInformationComponent,
    // DetailsClassificationSegmentComponent,
    // DetailsClassificationMaterialGroupsComponent,
    // DetailsSourcingStrategyComponent,
    // ToastComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    // NgxDatatableModule,
    MatrisneocommonModule,
    ReactiveFormsModule,
    ProgressBarModule,
    // DetailsModule,
    BrowserAnimationsModule,
    ScrollPanelModule
    // TegelModule
  ],
  exports: [
    MatrisneocommonModule,
    ReportIssueComponent
    // TegelModule, 
    // ToastComponent,
    // DetailsModule
  ],
  providers: [DatePipe,
    {
      provide: RouteReuseStrategy,
      useClass: RouteReuseService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    // ToastService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
      deps: [TokenService, MsalService]
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
