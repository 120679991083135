import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'neo-matris-textarea',
  templateUrl: './matris-textarea.component.html',
  styleUrls: ['./matris-textarea.component.scss']
})
export class MatrisTextareaComponent {
  @Input() value :any
  @Input() id : string = ''
  @Input() width = '100%'
  @Input() isDisabled = false
  @Input() label : string = ''
  @Output() valueInputChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueChange:EventEmitter<any> = new EventEmitter<any>();


  onValueChange(event: any) {
    this.valueChange.emit({ id: this.id, event: event });
  }

  onValueInputChange(event: any) {
    this.valueInputChange.emit({ id: this.id, event: event });
  }

  onInputChange(event: any) {
    const newValue = event.target.value;
    this.value = newValue;
  }
  
}
