import { Component, Input, Output, EventEmitter} from '@angular/core';
import { ReportIssueDialogService } from '../services/report-issue-dialog.service';
import { MatrisToastService } from 'src/app/matrisneocommon/matris-toast.service';
import { ToastMessageSeverity } from 'src/app/enums/ToastMessageSeverity.enum';
import { MatrisLoaderHandlerService } from '../matrisneocommon/matris-loader-handler.service';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-report-issue',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.scss']
})
export class ReportIssueComponent {
  @ViewChild('fileInput') fileInput!: ElementRef;
  feedback: any = {title: '', description: ''};
   file: File | null = null;
  // file: File
  fileError: string | null = null;
  isFeedbackDialogVisible: boolean = false;
  dialogVisible = false;
  readonly fileMaxSize = 10 * 1024 * 1024;
  // readonly allowedFileTypes = ['image/png', 'image/jpeg', 'application/pdf'];
  username: string;


  constructor(private reportDialogService: ReportIssueDialogService,  private toastService: MatrisToastService, private matrisLoaderHandlerService : MatrisLoaderHandlerService){}
  
  ngOnInit() {
    this.reportDialogService.getShowReportIssueDialogStatus().subscribe(state => {
      this.dialogVisible = state
    });
  }
  // openFeedbackDialog() {
  //   this.dialogVisible = true;
  // }
  closeFeedbackDialog() {
    this.reportDialogService.setShowReportIssueDialogStatus(false);
    this.resetForm();
  }

  onInputChange(field: string, event: any) {
    let value = event?.event?.target?.value || ''; 
    if (value != null || value != undefined) {
        event.event.target.value = value; 
      this.feedback[field] = (value || "").toString().trim(); 
    }
  
  }
  

  isFormValid(): boolean {
    const isTitleValid = this.feedback.title.trim().length > 0 && this.feedback.title.length <= 250;
    const isDescriptionValid = this.feedback.description.trim().length > 0 && this.feedback.description.length <= 1000;
    const isFileValid = this.file ? this.file.size <= this.fileMaxSize : true;
    const isValid = isTitleValid && isDescriptionValid && isFileValid;
  
   return isValid;
  }

async onFileSelected(event: any): Promise<void> {
  const file = event.target.files[0];
  if (file) {

    if (file.size > this.fileMaxSize) {
      // Reset the file input
      event.target.value = null;
      this.toastService.showToast(
        ToastMessageSeverity.Information,
        'Validation Error',
        `File size exceeds the maximum limit of 10MB.`
      );
      return;
    }

    // Proceed with valid file
    this.file = file;
   
  }
  else {
    this.file = null; // Reset file reference
  }
}


async submitFeedback(): Promise<void> {
  if (!this.isFormValid()) {
    return;
  }

  this.matrisLoaderHandlerService.addCount();

  try   {
    const date = new Date();
    const formattedDate = date.toLocaleString('en-GB');
    const userName = localStorage.getItem('username') || "";
    const userId = localStorage.getItem('globalId') || "";
    
    if (this.file) {
    const base64File = await this.convertFileToBase64(this.file);
    const fileName = this.file.name;

    // Step 1: Upload the file and get the imageRefId
    const data = {
      fileName: fileName,
      fileContent: base64File.split(',')[1], // Remove the data URL prefix
    };

    console.log(data);
    this.reportDialogService.uploadFile(data).subscribe({
      next: (uploadResponse: any) => {
        console.log('File upload response:', uploadResponse);
        
        const imageRefId = uploadResponse.imageRefId; // Extract reference ID from the response
         // British locale
        console.log(formattedDate);


        const emailData = {
          "title": this.feedback.title,
          "description": this.feedback.description,
          "imageRefId": imageRefId,
          "userId":userId,
          "createdDate":formattedDate,
          "createdBy": userName,
          "filename": fileName
        }

        // Call createIssueSendMail API
        this.reportDialogService.createIssueSendMail(emailData).subscribe({
          next: () => {
            this.toastService.showToast(
              ToastMessageSeverity.Success,
              'Success',
              'Issue sent successfully!'
            );
            this.closeFeedbackDialog();
            this.resetForm();
            this.hideLoader();
            
          },
          error: (error: any) => {
            console.error('Error sending email:', error);
            this.toastService.showToast(
              ToastMessageSeverity.Error,
              'Error',
              'Failed to send feedback email.'
            );
             this.hideLoader();
          
          },
        });
      },
      error: (uploadError: any) => {
        console.error('Error uploading file:', uploadError);
        this.toastService.showToast(
          ToastMessageSeverity.Error,
          'Error',
          'Failed to upload the file.'
        );
       this.hideLoader();
      
      },
    });
  } else {
    // If no file is uploaded, proceed to send feedback
    const emailData = {
      title: this.feedback.title,
      description: this.feedback.description,
      imageRefId: "",
      userId:userId,
      createdDate: formattedDate,
      createdBy: userName,
      filename: ""
    };
    this.reportDialogService.createIssueSendMail(emailData).subscribe({
      next: () => {
        this.toastService.showToast(
          ToastMessageSeverity.Success,
          'Success',
          'Issue sent successfully!'
        );
        this.closeFeedbackDialog();
         this.resetForm();
        this.hideLoader();
      },
      error: (error: any) => {
        console.error('Error sending email:', error);
        this.toastService.showToast(
          ToastMessageSeverity.Error,
          'Error',
          'Failed to  feedback email.'
        );
        this.hideLoader();
      },
    });
  }
  } catch (error) {
    console.error('Error processing feedback submission:', error);
    this.toastService.showToast(
      ToastMessageSeverity.Error,
      'Error',
      'Error processing feedback submission.'
    );
    this.hideLoader();
  }
}
private hideLoader(): void {
  this.matrisLoaderHandlerService. substractCount();
}

    private convertFileToBase64(file: File): Promise<string> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result as string); // Return the base64 string
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file); // Start reading the file as a base64-encoded string
      });
    }
  resetForm() {
    this.feedback.title = '';
    this.feedback.description = '';
    this.file = null;
    this.fileError = null;
    this.isFeedbackDialogVisible = false; 
    
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }
  

}
