import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportIssueDialogService {
  constructor(private httpClient: HttpClient) { }
  baseUrl = environment.baseUrl
  //private dialogSubject = new Subject<boolean>();
  private showReportIssueDialog: BehaviorSubject<boolean> = new BehaviorSubject(false);

  getShowReportIssueDialogStatus(): Observable<boolean> {
      return this.showReportIssueDialog.asObservable();
  }

  setShowReportIssueDialogStatus(showDialog: boolean) {
      this.showReportIssueDialog.next(showDialog);
  }

  uploadFile(body:any){
  return this.httpClient.post(this.baseUrl + `/upload_matris_neo_issues`, body);
  }

  // createIssueSendMail(issueId:number,title: string, description: string,fileName:string,imageRefId:string,userId:string,createdBy:string,createdDate:string) {
  //   return this.httpClient.get(this.baseUrl + `/send_matris_neo_issues_mail?issueId=${issueId}&title=${title}&description=${description}&fileName=${fileName}&imageRefId=${imageRefId}&userId=${userId}&createdBy=${createdBy}&createdDate=${createdDate}`);
  // }
  // createIssueSendMail(issueId:number,title: string, description: string,fileName:string) {
  //   return this.httpClient.get(this.baseUrl + `/send_matris_neo_issues_mail?issueId=${issueId}&title=${title}&description=${description}&fileName=${fileName}`);
  // }
  createIssueSendMail(body:any) {
    return this.httpClient.post(this.baseUrl + `/send_matris_neo_issues_mail`, body);
  }
}
