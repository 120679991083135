<neo-matris-loader></neo-matris-loader>
<neo-matris-dialog class="custom-dialog" [visible]="dialogVisible" [modal]="true" [closable]="true"
  (onHide)="closeFeedbackDialog()" header="Report Issue/Feedback" [style]="{ width: '500px' }">
  <div>
  
    <div class="p-field" style="margin-bottom: 10px;">
      <label for="title">Title*</label>
      <input  id="title" [(ngModel)]="feedback.title" [maxlength]="'250'" required #title="ngModel" class="p-inputtext"/>
    </div>

    <div class="p-field" style="margin-bottom: 10px;">
      <label for="description">Description*</label>
      <textarea class="custom-textarea" id="description" [(ngModel)]="feedback.description" [maxlength]="'1000'" required
        #description="ngModel"></textarea>
    </div>

    <div class="p-field">
      <input tooltipPosition="top" type="file" #fileInput (change)="onFileSelected($event)"/>
      <small *ngIf="fileError" class="p-error">{{ fileError }}</small>
    </div>

    <div class="p-dialog-footer">
      <neo-matris-button [buttonText]="'Submit'" [disabled]="!isFormValid()" (click)="submitFeedback()"></neo-matris-button>
      <neo-matris-button [buttonText]="'Cancel'" (click)="closeFeedbackDialog()"></neo-matris-button>
    </div>
  </div>
</neo-matris-dialog>
